import React from "react";
import styled from "styled-components";
import popimage from "./modal_image/Thank_you_pop_up.jpg";
import CloseButton from "./icons/CloseButton";

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0,0);
    position:absolute;
    top:14%;
    left:0%;
    padding:0 50px;
    justify-content: center;
    align-items: center;
`
const ModalWrapper = styled.div`
width: 100%;
height: auto;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background: #fff;
    color:  #000;
    display: block;
    grid-template-columns: 1fr 1fr;
    position:relative;
    z-index: 10;
    border-radius: 10px;
 `
const ModalImg = styled.img`
        width: 100%;
        height: auto;
         border-radius: 10px 0 0 10px;
         background: #000;
     `
const ModalContent = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #141414;

    p {
        margin-bottom: 1rem;
    }

    button {
        padding: 10px 24px;
        background: #141414;
        color: #fff;
        vorder: none;
    }
`;

// const ModalWrapper = styled.div`
//     width: 800px;
//     height: 500px;
//     box-shadow: 0 5px 16px rgba(0,0,0,0.2);
//     background: #fff;
//     color:  #000;
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     position:relative;
//     z-index: 10;
//     border-radius: 10px;
// `
// const ModalImg = styled.img`
//     width: 100%;
//     height: 100%;
//     border-radius: 10px 0 0 10px;
//     background: #000;
// `
// const ModalContent = styled.div`
//     display:flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     line-height: 1.8;
//     color: #141414;

//     p {
//         margin-bottom: 1rem;
//     }

//     button {
//         padding: 10px 24px;
//         background: #141414;
//         color: #fff;
//         vorder: none;
//     }
// `;

// const CloseModalButton = styled(Mdclose)`
//     cursor: pointer;
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     width: 32px;
//     height: 32px;
//     padding: 0;
//     z-index: 10;
// `;

export const Modal = ({showModal,setShowmodal,close}) => {
    //setShowmodal();
    return (
        <>
        {showModal ? (
            <Background>
                <ModalWrapper showModal={showModal}>
                    <ModalImg src={popimage} alt="contactUs"/>
                    <CloseButton    
                  
                  onClick={() => {
                  
                    close();
                  }}
                             style={{position: "absolute",top:"15px", right:"15px",height:"20px"}}
                          />
                </ModalWrapper>
            </Background>
        ):null}
            
        </>
    )
}

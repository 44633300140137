import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  width: 100%;
  position:relative;
  background-color: rgba(255, 255, 255, 0.95);
  padding-top: 35px;
  padding-bottom: 50px;
  margin-bottom: 5px;
  & form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    & p {
      display: flex;
      justify-content: space-between;
      & > span {
        display: inline-block;
        min-width: 25px;
      }
    }
    & label {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: var(--slate);
      font-size: 15px;
      line-height: 1.4;
      letter-spacing: 0.3px;
      & input,
      & textarea {
        border: 1px solid var(--mid);
        box-sizing: border-box;
        height: 36px;
        padding: 8px;
        margin-top: 4.5px;
        line-height: 1.4;
        letter-spacing: 0.3px;
        width: 100%;
        font-family: var(--headerFont);
        font-size: 15px;
        color: var(--black);
      }
      & textarea {
        height: 144px;
      }
    }
    & input[type="submit"],
    & button {
      height: 56px;
      width: 400px;
      font-family: var(--headerFont);
      background-color: var(--green);
      box-shadow: none;
      outline: none;
      appearance: none;
      color: var(--black);
      font-size: 24px;
      line-height: 32px;
      font-style: italic;
      border: none;
      margin-top: 50px;
      text-transform: uppercase;
      cursor: pointer;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    & > form {
      margin-bottom: 0;
      & > div {
        padding: 0 !important;
      }
    }
  }
`;

import React, {useState} from "react";
import { ContactUsWrapper } from "./elements";
import emailjs from 'emailjs-com';
import { Modal } from "../Model";
import { GlobalStyle } from "../../globalStyles";
const ContactUsForm = ({ newsletter, callToAction }) => { 
const [showModal, SetShowModal] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();   
    
      var newsfeedLetters = e.target.newsletterSignup
      if(newsfeedLetters === undefined){
        emailjs.sendForm('service_b63obcc', 'template_z1ny2un', e.target, '1oiFBKwf2u2uXZh0F')
            .then((result) => {            
               SetShowModal(prev => !prev)              
            }, (error) => {
                console.log(error.text);
            });
        
            } else {
             emailjs.sendForm('service_b63obcc', 'template_8asrmxm', e.target, '1oiFBKwf2u2uXZh0F')          
              .then((result) => {             
                      SetShowModal(prev => !prev)                  
              }, (error) => {
                  console.log(error.text);
              });
              }
      e.target.reset();
            }
  return (
    <ContactUsWrapper>
      <form onSubmit={sendEmail} >
        <p>
          <label>
            <input type="hidden" name="from_name" value="info@alserkal.online"></input>
            First name<input type="text" name="First_Name" required></input>
          </label>
          <span>&nbsp;</span>
          <label>
            Last name<input type="text" name="Last_name" required></input>
          </label>
        </p>
        {newsletter ? null : (
          <React.Fragment>
            <p>
              <label>
                Company name – optional
                <input type="text" name="Company_name_optional"></input>
              </label>
            </p>
            <p>
              <label>
                Mobile number<input type="text" name="Mobile_number"></input>
              </label>
            </p>
          </React.Fragment>
        )}
        <p>
          <label>
            Email address<input type="email" name="Email_address" required></input>
          </label>
        </p>
        {newsletter ? (
          <input type="hidden" value="newsletterSignup" name="newsletterSignup" />
        ) : (
          <div>
            <label>
              Message
              <textarea name="Message" />
            </label>
          </div>
        )}
       
        <input type="submit"   value={callToAction || "Hello"} name="Send" />
        <Modal showModal={showModal} SetShowModal={SetShowModal} close={() => {
          SetShowModal(false);
        }}/>       
        <GlobalStyle />
      </form>
    </ContactUsWrapper>
  );
};

export default ContactUsForm;


import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import styled from "styled-components";
import ContactUsMap from "./../components/ContactUsMap";
import ContactUsForm from "./../components/ContactUsForm";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";
// import { H3Strong } from "./../components/Typography";

// const HoursUL = styled.ul`
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
//   margin-bottom: 25px;
//   & li {
//     font-size: 18px;
//     display: flex;
//     letter-spacing: 2%;
//     margin-bottom: 7.2px;
//     & strong {
//       font-weight: 700;
//       width: 50%;
//     }
//   }
// `;

const PlanYourVisitPage = () => {
  const openingQuery = useStaticQuery(graphql`
    {
      contactUs: craftHowToGetHereHowToGetHereEntry {
        richTextField
        callToAction
      }
      openingHours: craftOpeningHoursOpeningHoursEntry {
        title
        richTextField
        openingHours {
          day
          hours
        }
      }
    }
  `);
  const contactUsRichText = openingQuery.contactUs.richTextField;
  const { callToAction } = openingQuery.contactUs;
  // const { openingHours, richTextField } = openingQuery.openingHours;

  return (
    <Layout
      backgroundColor={"var(--red)"}
      headerColor={"var(--white)"}
      title={"Alserkal: How to get here"}
    >
      <StandalonePageLayout>
        <h1>How to get here</h1>
        <div>
          <ContactUsMap />
          {/* Block of text coming from How to get here page */}
          {/*<WhiteBox>
            <div>
              <HoursUL>
                <H3Strong style={{ marginBottom: "20px" }}>
                  Opening hours:
                </H3Strong>
                {openingHours.map((hour, index) => (
                  <li key={index}>
                    <strong>{hour.day}</strong>
                    {hour.hours}
                  </li>
                ))}
              </HoursUL>
              <div dangerouslySetInnerHTML={{ __html: richTextField }} />
            </div>
								</WhiteBox>*/}
          <WhiteBox>
            <div dangerouslySetInnerHTML={{ __html: contactUsRichText }} />
          </WhiteBox>
          <ContactUsForm callToAction={callToAction} />
        </div>
      </StandalonePageLayout>
    </Layout>
  );
};

export default PlanYourVisitPage;

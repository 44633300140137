import React from "react";
import GoogleMapReact from "google-map-react";
import pin from "./images/pin.png";
const APIkey = "AIzaSyBX8Mvu6qT66fYldiFUWXfjSCBg4cASG3o";

const ImageCard = () => <img src={pin} width={50} height={"auto"} style={{width:'60px'}}/>;

const ContactUsMap = () => (
  <div
    style={{
      height: "375px",
      width: "100%",
      marginBottom: "5px",
      opacity: 0.9,
    }}
    className="map"
  >
    <GoogleMapReact
      bootstrapURLKeys={{ key: APIkey }}
      defaultCenter={{
        lat: 25.141861,
        lng: 55.226111,
      }}
      defaultZoom={17}
    >
      <ImageCard lat={25.141861} lng={55.226111} text={"Alserkal"} />
    </GoogleMapReact>
  </div>
);

export default ContactUsMap;
